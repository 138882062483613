import React, { useRef, useState, useEffect } from "react";
import { navigate } from "gatsby";

import * as CONST from "../../CONSTANTS";
import { JornayaHiddenInput } from "../thirdParty/jornaya";
import { getCurrentPhoneNumber } from "../thirdParty/leadspedia";

const isLocalhost = () => {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
};

export default function zipCode() {
  const QUERY = "Please enter your zip code";
  const PLACEHOLDER = "Zip code";
  const NEXT_CN = CONST.CN_ONESTEP_FORM;

  const inputEl = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  useEffect(() => {
    setIsNextButtonEnabled(isValidInputValue());
  }, [inputValue]);

  const isValidInputValue = () => {
    return inputValue !== "" && inputValue.length > 4;
  };

  const formatInput = input => {
    let updatedInp = input;
    updatedInp = updatedInp.replace(/[^0-9]/gi, "");

    if (updatedInp.length > 5)
      updatedInp = updatedInp.slice(0, updatedInp.length - 1);

    return updatedInp;
  };

  const onInputValueChange = event => {
    const { value } = event.target;

    setInputValue(formatInput(value));
  };

  const onNextClick = () => {
    if (isValidInputValue()) {
      const queryString = window.location.search.replace("?", "");
      const phoneNumber = getCurrentPhoneNumber();
      let componentInfo = {};
      componentInfo[CONST.DATA] = inputValue;
      componentInfo[CONST.NEXT_COMPONENT] = NEXT_CN;
      window.open(
        `/apply/?transfer=1&zip=${inputValue}&phoneNumber=${phoneNumber}&${queryString}`,
        "_blank"
      );

      if (!isLocalhost()) {
        navigate(`/exitOffers?${queryString}`, {
          state: { [CONST.CN_ZIP_CODE]: componentInfo },
          replace: true,
        });
      }
    }
  };

  return (
    <div className="frontpage-form-box form-box">
      <form
        onSubmit={e => {
          e.preventDefault();
          onNextClick();
        }}
      >
        <h2>{QUERY}</h2>
        <input
          ref={inputEl}
          className="form-control"
          type="text"
          value={inputValue}
          placeholder={PLACEHOLDER}
          onChange={evt => onInputValueChange(evt)}
        />
        <button
          className="btn btn-primary"
          disabled={!isNextButtonEnabled}
          type="submit"
        >
          SUBMIT
        </button>
        <JornayaHiddenInput />
      </form>
    </div>
  );
}
