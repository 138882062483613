import React, { useEffect } from "react";

import * as CONST from "../CONSTANTS";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HPZip from "../components/home/zipCode";

import { initJornaya } from "../components/thirdParty/jornaya";

const IndexPage = () => {
  useEffect(() => {
    initJornaya();
  }, []);

  return (
    <Layout layoutType={CONST.LAYOUT_HOME_PAGE}>
      <Seo title="Home" />

      <div className="container home">
        <div className="row">
          <div className="col-12">
            <h1 className="w-md-50 frontpage-heading">
              Find the best{" "}
              <span className="text-primary">Health&nbsp;plan</span> for the
              right price
            </h1>
          </div>
          <div className="col-12 col-md-6 order-2 order-md-1">
            <p className="mb-4">
              <b>Your health is your greatest asset.</b> Whether you need top of
              the line coverage or an unbeatable price, Healthy-Quotes has you
              covered. We can connect you with licensed professionals to guide
              you through the shopping and decision process to make sure you get
              exactly what you need.{" "}
              <span className="text-primary font-weight-bold">
                <b>
                  You&apos;ll be able to explore plan types, compare pricing,
                  and secure coverage all in one place.
                </b>
              </span>
            </p>

            <p>
              In order to provide accurate plan information, we will need to
              know a little more about you.{" "}
              <b>Just complete these simple steps to get started!</b>
            </p>
          </div>

          <div className="col-12 col-md-6 text-center d-flex align-items-end order-1 order-md-2  mb-4 mb-md-0 ">
            <HPZip />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
